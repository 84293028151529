import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import * as fontAwesomeLibrary from './font-awesome-library';
import theme from './theme';

import '@edf-pkg/assets/fonts/open-sans/style.css';
import './reset.css';

fontAwesomeLibrary.initialize();

const RootWrapper = (props) => {
    const { children } = props;
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

RootWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default RootWrapper;
