// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:

import '$common/fontawesome-pro/fontawesome-svg-core/styles.css';

import { faAngellist, faApple, faFacebookF, faGooglePlay, faLinkedinIn, faTwitter } from '$common/fontawesome-pro/brands';
import {
    faAlarmClock as faAlarmClockDuo,
    faAnalytics as faAnalyticsDuo,
    faAngleRight as faAngleRightDuo,
    faAngleUp as faAngleUpDuo,
    faAppleAlt as faAppleAltDuo,
    faBallotCheck as faBallotCheckDuo,
    faBars as faBarsDuo,
    faBinoculars as faBinocularsDuo,
    faBook as faBookDuo,
    faBrain as faBrainDuo,
    faChartNetwork as faChartNetworkDuo,
    faClipboardListCheck as faClipboardListCheckDuo,
    faComments as faCommentsDuo,
    faDatabase as faDatabaseDuo,
    faDna as faDnaDuo,
    faEnvelope as faEnvelopeDuo,
    faFileInvoice as faFileInvoiceDuo,
    faFillDrip as faFillDripDuo,
    faGraduationCap as faGraduationCapDuo,
    faHandsHelping as faHandsHelpingDuo,
    faHandsUsd as faHandsUsdDuo,
    faHeadSideBrain as faHeadSideBrainDou,
    faInfoCircle as faInfoCircleDuo,
    faMask as faMaskDuo,
    faMicroscope as faMicroscopeDuo,
    faNewspaper as faNewspaperDuo,
    faPencilRuler as faPencilRulerDuo,
    faSearch as faSearchDuo,
    faSensorOn as faSensorOnDuo,
    faSlidersV as faSlidersVDuo,
    faUniversity as faUniversityDuo,
    faUserAstronaut as faUserAstronautDuo,
    faUserNurse as faUserNurseDuo,
    faUsers as faUsersDuo,
    faVirus as faVirusDuo,
    faWebcam as faWebcamDuo,
} from '$common/fontawesome-pro/duotone';
import { config, library } from '$common/fontawesome-pro/fontawesome-svg-core';
import {
    faAngleDown as faAngleDownLight,
    faAngleLeft as faAngleLeftLight,
    faAngleRight as faAngleRightLight,
    faAngleUp as faAngleUpLight,
    faEllipsisHAlt as faEllipsisHAltLight,
    faLongArrowRight as faLongArrowRightLight,
    faSearch as faSearchLight,
} from '$common/fontawesome-pro/light';
import { faLongArrowRight as faLongArrowRightSolid, faTimes as faTimesSolid } from '$common/fontawesome-pro/solid';

// Prevent fontawesome from adding its CSS since we did it manually above:
config.autoAddCss = false;

const icons = [
    // Light
    faLongArrowRightLight,
    faAngleUpLight,
    faAngleDownLight,
    faAngleRightLight,
    faAngleLeftLight,
    faEllipsisHAltLight,
    faSearchLight,
    // Brands
    faLinkedinIn,
    faFacebookF,
    faTwitter,
    faAngellist,
    faApple,
    faGooglePlay,
    // Duotone
    faFileInvoiceDuo,
    faSensorOnDuo,
    faUsersDuo,
    faAppleAltDuo,
    faUserNurseDuo,
    faWebcamDuo,
    faSearchDuo,
    faAnalyticsDuo,
    faSlidersVDuo,
    faPencilRulerDuo,
    faHandsHelpingDuo,
    faHandsUsdDuo,
    faAngleRightDuo,
    faAngleUpDuo,
    faMicroscopeDuo,
    faUniversityDuo,
    faUserAstronautDuo,
    faChartNetworkDuo,
    faAlarmClockDuo,
    faUsersDuo,
    faDatabaseDuo,
    faBinocularsDuo,
    faHeadSideBrainDou,
    faDnaDuo,
    faVirusDuo,
    faBrainDuo,
    faClipboardListCheckDuo,
    faFillDripDuo,
    faBookDuo,
    faInfoCircleDuo,
    faNewspaperDuo,
    faGraduationCapDuo,
    faCommentsDuo,
    faMaskDuo,
    faEnvelopeDuo,
    faBallotCheckDuo,
    faBarsDuo,
    // Solids
    faLongArrowRightSolid,
    faTimesSolid,
];

export function initialize() {
    library.add(...icons);
}
